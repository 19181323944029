
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  data() {
    return {
      openDialogWatch: false,
    };
  },
  methods: {
    CloseDeleteModal() {
      this.$emit("CloseNoPermission");
    },
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  props: {
    openDialog: Boolean,
  },
});
