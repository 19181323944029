
import IoT from "@/components/iot.vue";
import ChatWindow from "@/views/Chat/ChatWindow.vue";
import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import Authentication from "@/Mixins/Authentication";
import Account from "@/Mixins/Account";
import ChangePassword from "@/views/Users/ChangePassword.vue";
import SelectLanguage from "@/views/GlobalAccount/ChangeLanguage.vue";
import { Trans } from '@/plugins/trans';
import Project from "@/Types/Project";
import CoreLanguage from "@/Types/CoreLanguages";
import Country from "@/Types/Country";

export default mixins(Common, Authentication, Account).extend({
  components: {
    IoT,
    ChatWindow,
    ChangePassword,
    SelectLanguage
  },
  mounted() {
    if (!this.hasLanguages && !this.$store.state.LanguagesLoading) {
      this.$store
        .dispatch("GetLanguages")
        .then(() => { })
        .catch((e: any) => {
          this.LogError(e);
          this.PushToRoute("MajorError");
        });
    }
    this.$root.$on("Unauthorized", () => {
      this.SetUnauthorized();
    });
  },
  data: function () {
    return {
      drawer: false,
      OpenPasswordDialog: false,
      IsReAuthenticating: false,
      OpenSelectLanguage: false,
      SearchClose: true,
      SearchTerm: "",
      isSearchLoading: false,
      select: null,
      searchItems: [],
      filterString: "",
      timeout: {} as any,
      lastTimeStamp: 0,
      ShowProjectResults: true,
      SearchHasClick: false
    };
  },
  name: "LangaugeWrap",
  computed: {
    Initials(): string {
      if(this.CurrentUser == undefined || this.CurrentUser.firstName == null || this.CurrentUser.surName == null)
      {
        return  "";
                  }
      return this.CurrentUser.firstName.charAt(0)+ this.CurrentUser.surName.charAt(0);
    },
    Language(): string {
      return this.$route.params.lang;
    },
    IsInProject(): boolean {
      if (this.$route.path.indexOf("projects") != -1 && this.$route.params["id"] != undefined) {
        return true;
      }
      return false;
    },
    InAccountSelect(): Boolean {
      return this.currentRoute == "AccountSelector";
    },
    currentRoute(): string | null | undefined {
      return this.$route.name;
    },
    AccountName(): string {
      if (this.AccountOwnership == null || this.AccountOwnership.companyName == null) {
        return "";
      }
      return this.AccountOwnership.companyName;
    },
    CurrentProject(): Project | null {
      if (
        this.$store.state.Projects !== undefined &&
        this.$store.state.Projects.length !== 0 && this.IsInProject
      ) {
        const c = this.$store.state.Projects.find(
          (p: any) => p.externalId === this.$route.params["id"]
        );
        if (c == undefined) {
          return null;
        } else {
          return c;
        }
      } else {
        return null;
      }
    },
    CurrentProjectName(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.name;
    },
    ProjectId(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.externalId;
    }
  },
  watch: {
    SupportedLanguages() {
      if (this.CommonCurrentLanguage != this.SupportedLanguages && this.hasLanguages) {
        this.FormatLanguageNames();
        this.CommonCurrentLanguage = this.SupportedLanguages;
      }
    },
    Language() {
      Trans.changeLanguage(this.Language);
    },
    SearchTerm() {
      if (this.SearchTerm != null && this.SearchTerm != "") {
        this.$nextTick(function () {
          if (this.SearchHasClick) {
            this.searchItems = [];
            this.SearchTerm = "";
            this.SearchHasClick = false;
          }
        });
      }
    },
    ShowProjectResults() {
      this.runQuerySelections(this.filterString, this.getTimeStamp())
    },
    async auth0Loading() {
      if (!this.auth0Loading) {
        try {
          const accessToken = await this.$auth.getTokenSilently();
          this.$store
            .dispatch("SetAuthToken", accessToken)
            .then(() => { })
            .catch((e: any) => {
              this.LogError(e);
              this.PushToRoute("MajorError");
            });
        } catch (e: any) {
          let lang = this.$route.params.lang;

          if (!Trans.isLangSupported(lang)) {
            lang = Trans.getUserSupportedLang();
          }
          if (e.error === "login_required") {
            this.IsReAuthenticating = false;

            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          if (e.error === "consent_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          throw e;
        }
      }
    },
  },
  methods: {
    FormatLanguageNames() {
      let langs = this.$store.state.Languages as CoreLanguage[];
      langs.forEach((element: CoreLanguage) => {
        const langTitle = `Language-${element.isoLocalId}`;

        element.languageTitle = this.$i18n.t(langTitle) + " (" + element.isoLocalId + ")"
      });
      langs = langs.sort(function (a: CoreLanguage, b: CoreLanguage) {
        let nameA = a.languageTitle.toUpperCase(); // ignore upper and lowercase
        var nameB = b.languageTitle.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.$store.commit("UpdateLanguages", langs);


      let list = this.$store.state.Countries as Country[];
      list.forEach((element: Country) => {
        element.name = this.$i18n.t("Country-" + element.id.toUpperCase()).toString();
      });

      list = list.sort(function (a: Country, b: Country) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.$store.commit("UpdateCountries", list);

    },
    onBlurOfSearch() {
      if (this.filterString == "" || this.filterString == null) {
        this.SearchClose = true;
        this.isSearchLoading = false;
        this.searchItems = [];
        this.SearchTerm = "";
      }
    },
    ClearFilterString() {
      this.filterString = '';
      this.searchItems = [];
    },
    SearchToAccount() {
      this.ShowProjectResults = false;
    },
    SearchToProject() {
      this.ShowProjectResults = true;
    },
    setFilterString(e: any) {
      if (!e) {
        this.filterString = ''
      } else if (typeof e === 'string') {
        this.filterString = e
      } else if (typeof e === 'object') {
        this.filterString = e.srcElement.value
      }

      this.querySelections(this.filterString)
    },
    viewSelectLanguage() {
      this.OpenSelectLanguage = true;
    },
    CloseSelectLanguage() {
      this.OpenSelectLanguage = false;
    },
    logOut() {
      this.$auth.logout({
        returnTo: "http://localhost:8080/",
      });
    },
    ChangePassword() {
      this.OpenPasswordDialog = true;
    },
    ClosePasswordDialog() {
      this.OpenPasswordDialog = false;
    },
    SwtichAccount() {
      this.PushToRoute("AccountSelector");
    },
    OpenProjects() {
      if (this.$route.name != "projects") {
        this.PushToThisAccountRoute("projects");
      }
    },
    OpenOrders() {
      if (this.$route.name != "viewOrders") {
        this.PushToThisAccountRoute("viewOrders");
      }
    },
    viewAccount() {
      if (this.$route.name != "viewAccount") {
        this.PushToThisAccountRoute("viewAccount");
      }
    },
    ManagePaymentMethod() {
      this.PushToThisAccountRoute("ManagePaymentMethod");
    },
    OpenTasks() {
      if (this.$route.name != "viewTasksOverview") {
        this.PushToThisAccountRoute("viewTasksOverview");
      }
    },
    async SetUnauthorized() {
      if (!this.auth0Loading && !this.IsReAuthenticating) {
        this.IsReAuthenticating = true;
        //console.log("IsReAuthenticating");
        try {
          const accessToken = await this.$auth.getTokenSilently();
          this.$store
            .dispatch("SetAuthToken", accessToken)
            .then((response) => {
              if (response) {
                // alert("bad");
                //this.PushToRoute("AccountSelector");
                this.IsReAuthenticating = false;
              }
            })
            .catch((e: any) => {
              this.LogError(e);
              this.PushToRoute("MajorError");
            });
        } catch (e: any) {
          let lang = this.$route.params.lang;

          if (!Trans.isLangSupported(lang)) {
            lang = Trans.getUserSupportedLang();
          }
          if (e.error === "login_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          if (e.error === "consent_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          throw e;
        }
      }
    },
    querySelections(v: string) {
      window.clearTimeout(this.timeout);
      if (v.trim().length != 0) {
        if ((v.trim().length % 3) == 0) {
          this.runQuerySelections(v, this.getTimeStamp())
        } else {
          this.timeout = window.setTimeout(() => this.runQuerySelections(v, this.getTimeStamp()), 300);
        }
      }


    },
    getTimeStamp(): number {
      var isPerformanceSupported = (
        window.performance &&
        window.performance.now() &&
        window.performance.timing &&
        window.performance.timing.navigationStart
      );

      var timeStampInMs = (
        isPerformanceSupported ?
          window.performance.now() +
          window.performance.timing.navigationStart :
          Date.now()
      );
      return timeStampInMs;
    },
    runQuerySelections(v: string, timestamp: number) {
      this.isSearchLoading = true
      window.clearTimeout(this.timeout);
      // Simulated ajax query
      setTimeout(() => {
        this.$store
          .dispatch("DoSearch", { searchTerm: v, AccountId: this.AccountId, ShowProjectResults: this.ShowProjectResults, ProjectId: this.ProjectId })
          .then((results: any) => {
            if (timestamp > this.lastTimeStamp) {
              this.lastTimeStamp = timestamp;
              results.forEach((element: any) => {
                element.searchTerms.forEach((b: string) => {
                  element.matchedSearchTerm = this.boldString(element.matchedSearchTerm, b);
                });
                element.matchedSearchTerm
              });
              this.searchItems = results;
            }


          })
          .catch((e: any) => {
            this.LogError(e);
            // this.PushToRoute("MajorError");
          });



        this.isSearchLoading = false
      }, 500)
    },
    boldString(str: string, substr: string) {
      var strRegExp = new RegExp(substr, 'i');
      return str.replace(strRegExp, '<strong>' + substr + '</strong>');
    },
    HandleClickSearch(term: any) {
      this.filterString = "";
      this.SearchTerm = "";
      const lang = this.$route.params.lang;
      const accountId = this.$route.params.accountId;
      const id = term.projectExternalId;
      this.SearchHasClick = true;
      if (term.matchObject == "Project") {
        const name = "viewproject";
        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectKey" || term.matchObject == "ProjectKeyDetails") {

        const name = "viewsinglekey";
        const keyId = term.projectKeyExternalId;
        const sourceLanguage = lang;
        const targetLanguage = "fr";

        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id, keyId, sourceLanguage, targetLanguage },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectGlossary") {

        let name = "viewGlossary";
        const lang = this.$route.params.lang;
        let projectId = this.Id;
        const to = this.$router.resolve({
          name,
          params: { lang, projectId },
          query: { GlossaryId: term.matchObjectExternalId },
        });
        this.ShouldRoute(to);

      }
      else {
        alert(term.matchObject);
      }
    },
    ShouldRoute(to: any) {
      if (this.$route.fullPath != to.route.fullPath) {
        this.$router.push(to.location);
      }
    }
  },
});
