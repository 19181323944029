import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import Account from "@/Types/Account";

export default mixins(Common).extend({
  data() {
    return {
      AccountOwnership: {
        companyName: "",
        vatNumber: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        countryId: "",
      },
      RA:false,
      overLimit: false,
      Tariff: [] as any,
      NextBilling: "",
      PaymentMethod: "",
      includedProjects: "",
      KeysInUse: 0,
      SeatsInUse: 0,
      CostPerSeat: "",
      ProjectsInUse: 0,
      CostOfExtraKeys: "",
      MonthlyBaseCost: "",
      UpdatingAccount: false,
      LastMonthsCost: "",
      loaded: false,
      PaymentMethodsloaded: "" as any,
      PaymentMethods: [] as any,
      ExistingPaymentMethod: "",
      authToken: "sandbox_7bn2z2qb_w4x3bxqy4j3hyg87",
    };
  },
  methods: {
    loadAccount() {
      if (this.isAuthenticated) {
        if (this.$store.state.AccountLoaded) {
          this.ProcessAccount();
        } else if (this.AccountId != undefined && this.AccountId != null) {
          this.$store
            .dispatch("GetAccountInformation", this.AccountId)
            .then(() => {
              this.ProcessAccount();
            })
            .catch((error) => {
              this.CheckError(error);
            });
        }
      }
    },
    loadPaymentMethods() {
      if (this.isAuthenticated && !this.PaymentMethodsloaded) {
        this.ReloadPaymentMethods();
      }
    },
    ReloadPaymentMethods() {
      if (this.isAuthenticated) {
        this.PaymentMethodsloaded = false;
        this.$store
          .dispatch("GetPaymentMethods", this.AccountId)
          .then((data: any) => {
            data.forEach((element: any) => {

              if(element.cardType == "PayPal")
              {
                element.Label = "PayPal - "+ element.paymentMethodName
              }
              else
              {
              const i = new Date(element.expiry); //Date.parse(element.expiry);
              element.Label = `${element.cardType} ${this.$i18n.t(
                "EndingWith"
              )} ${element.lastFour} - ${this.$i18n.t("Expiry")} ${
                i.getUTCMonth() + 1
              }/${i.getUTCFullYear()}`;
              }
            });
            this.PaymentMethods = data;
            this.PaymentMethodsloaded = true;
          }).catch((error) => {
            this.CheckError(error);
          });
      }
    },
    ProcessAccount() {
      const data = this.$store.state.Account as Account;
      if(data ==null)
      {
        return;
      }
      this.RA = data.ra;
      this.AccountOwnership.companyName = data.companyName;
      this.AccountOwnership.vatNumber = data.vatNumber;
      this.AccountOwnership.email = data.email;
      this.AccountOwnership.address1 = data.address1;
      this.AccountOwnership.address2 = data.address2;
      this.AccountOwnership.city = data.city;
      this.AccountOwnership.state = data.state;
      this.AccountOwnership.countryId = data.countryId;
      this.Tariff = data.tariff;
      if (data.paymentMethod == undefined || data.paymentMethod == null) {
        this.PaymentMethod = this.$i18n.t("None").toString();
      } else {
        //let i = new Date(data.paymentMethod.expiry); //Date.parse(element.expiry);
        this.PaymentMethod = `${data.paymentMethod.cardType} ${this.$i18n.t(
          "EndingWith"
        )} ${data.paymentMethod.lastFour}`;
      }

      if (data.tariff.isFreeAccount) {
        this.NextBilling = this.$i18n.t("NotApplicable").toString();
        this.includedProjects = data.tariff.includedProjects.toString();
        this.MonthlyBaseCost = this.$i18n.t("Free").toString();
        this.CostPerSeat = this.$i18n.t("NotApplicable").toString();
        this.CostOfExtraKeys = this.$i18n.t("NotApplicable").toString();
      } else {
        const date = new Date(data.nextBilling);
        const Datestring = date.toLocaleDateString(["en-AU"], {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

        this.NextBilling = Datestring;
        this.includedProjects = this.$i18n.t("Unlimited").toString();
        this.MonthlyBaseCost = this.$i18n
          .n(data.tariff.baseCost, "currency")
          .toString();
        this.CostPerSeat = this.$i18n
          .t("PerSeat", {
            price: this.$i18n.n(data.tariff.seatCost, "currency"),
          })
          .toString();
        this.CostOfExtraKeys = this.$i18n
          .t("PerKeys", {
            price: this.$i18n.n(data.tariff.keyBlockSizeCost, "currency"),
            keyCount: data.tariff.keyBlockSize,
          })
          .toString();

        this.LastMonthsCost = this.$i18n.n(data.lastBillingAmount, "currency");
      }

      this.KeysInUse = data.keysInUse;
      this.SeatsInUse = data.seatsInUse;
      this.ProjectsInUse = data.projectsInUse;
      this.overLimit = data.overLimit;
      this.loaded = true;
      this.UpdatingAccount = false;
      if (data.paymentMethod != null) {
        this.ExistingPaymentMethod = data.paymentMethod.externalId;
      }
    },
  },
  computed: {

    IsAccountsLoading(): boolean {
      if (!this.isAuthenticated) {
        return true;
      }
      return !this.loaded;
    },
    CurrentAccount(): Account {
      return this.$store.state.Account;
    },
    PanelWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return "80%";
        case "xl":
        default:
          return "70%";
      }
    },
  },
  watch: {
    isAuthenticated() {
      this.loadAccount();
    },
    CurrentAccount() {
      this.ProcessAccount();
    },
    SupportedLanguages() {
      if(this.PaymentMethodsloaded) {
        this.PaymentMethods.forEach((element: any) => {
          const i = new Date(element.expiry); //Date.parse(element.expiry);
          element.Label = `${element.cardType} ${this.$i18n.t(
            "EndingWith"
          )} ${element.lastFour} - ${this.$i18n.t("Expiry")} ${
            i.getUTCMonth() + 1
          }/${i.getUTCFullYear()}`;
        });
      }
    }
  },
  mounted() {
    this.loadAccount();
    this.$root.$on("AccountUpdate", (event: any) => {
      if (this.loaded) {
        this.ProcessAccount();
      }
    });
  },
});
