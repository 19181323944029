
import CoreLanguage from "@/Types/CoreLanguages";
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  data() {
    return {
      openDialogWatch: false,
    };
  },
  computed: {
    SupportedLanguages(): CoreLanguage[] {
      return this.$store.state.SupportedLanguages;
    }
  },
  methods: {
    CloseSelectLanguage() {
      this.$emit("CloseSelectLanguage");
    },
    ChangeLanguage(Lang:CoreLanguage) {
      let lang = Lang.isoLocalId;
      const to = this.$router.resolve({ params: {lang  } });
      this.$router.push(to.location);
      this.CloseSelectLanguage();
    }
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  props: {
    openDialog: Boolean,
    projectId: String,
  },
});
