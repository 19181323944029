import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import Account from "@/Types/Account";
import Project from "@/Types/Project";
import Language from "@/Types/Language";
import CoreLanguage from "@/Types/CoreLanguages";
import User from "@/Types/User";
import Country from "@/Types/Country";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //baseUrl: 'https://uat.api.language.monster',
    baseUrl: "https://api.language.monster",
    //baseUrl: "https://localhost:5001",
    //baseUrl: "https://localhost:5555",
    authToken: null as string | null,
    Projects: [] as Project[],
    ProjectsLoading: false,
    ProjectsLoaded: false,
    Languages: [] as CoreLanguage[],
    SupportedLanguages: [] as CoreLanguage[],
    LanguagesLoading: false,
    SupportedLanguagesLoading: false,
    CurrentUser: {} as User,
    IsAuthenticated: false,
    UserLoading: false,
    NewUserEmail: "",
    DownloadFormats: [] as any,
    Account: null as Account | null,
    AccountLoaded: false,
    AccountIsLoading: false,
    UnknownAccount: false,
    Countries: [] as Country[],
    CountriesLoaded: false,
    CountriesLoading: false,
    SelectedKeyStore: [] as String[] | null
  },
  mutations: {
    UpdateProjects(state, payload: Project[]) {
      state.Projects = payload.sort(function (a: Project, b: Project) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      state.ProjectsLoaded = true;
      state.ProjectsLoading = false;
    },
    UnknownAccount(state, payload: boolean) {
      state.UnknownAccount = payload;
    },
    UpdateAccountInformation(state, payload: Account | null) {
      state.AccountLoaded = true;
      state.Account = payload;
    },
    CreateAuthToken(state, payload: string | null) {
      state.IsAuthenticated = true;
      state.authToken = payload;
    },
    UpdateUser(state, payload: User) {
      state.CurrentUser = payload;
    },
    UpdateDownloadFormats(state, payload) {
      state.DownloadFormats = payload;
    },
    SetActiveAccount(state, payload) {
      if (payload != undefined) {
        state.AccountLoaded = false;
        state.Account = null;
        //alert("hello");
        sessionStorage.setItem("AccountExternalId", payload.accountExternalId);
        sessionStorage.setItem(
          "AccountPermissions",
          payload.accountPermissions
        );
      }
    },
    UpdateSingleProject(state, payload: Project) {
      state.Projects = state.Projects.filter((value: Project) => {
        // debugger;
        return value.externalId !== payload.externalId;
      });

      state.Projects.push(payload);

      state.Projects = state.Projects.sort(function (a: Project, b: Project) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    RemoveProject(state, payload: string) {
      state.Projects = state.Projects.filter((value: Project) => {
        // debugger;
        return value.externalId !== payload;
      });
    },
    UpdateSingleLanguage(state, payload: Language) {
      const k = state.Projects.find(
        (e: Project) => e.externalId === payload.projectExternalId
      );

      if (k === undefined || k == null) {
        return;
      }

      k.languages = k.languages.filter((value: any) => {
        // debugger;
        return value.externalId !== payload.externalId;
      });

      k.languages.push(payload);
    },
    DeleteUploadfileComplete(state, payload) {},
    UpdateLanguages(state, payload: CoreLanguage[]) {
      state.Languages = payload;
      state.LanguagesLoading = false;
    },
    UpdateSupportedLanguages(state, payload: CoreLanguage[]) {
      state.SupportedLanguages = payload;
      state.SupportedLanguagesLoading = false;
    },
    AddProjects(state, payload: Project) {
      state.Projects.push(payload);
    },
    UpdateCountries(state, payload: Country[]) {
      state.Countries = payload;
      state.CountriesLoaded = true;
      state.CountriesLoading = false;
    },
    SetSelectedKeyStore(state, payload: String[] | null) {
      state.SelectedKeyStore = payload
    }
  },
  actions: {
    SetAuthToken(context, payload) {
      return new Promise((resolve, reject) => {
        this.state.UserLoading = true;
        axios
          .get(context.state.baseUrl + "/api/User", {
            headers: { Authorization: `Bearer ${payload}` },
          })
          .then((response) => {
            // JSON responses are automatically parsed.

            if (response.data.linkedAccounts.length == 1) {
              context.commit(
                "SetActiveAccount",
                response.data.linkedAccounts[0]
              );
              context.commit("UpdateUser", response.data);
              context.commit("CreateAuthToken", payload);
              this.state.UserLoading = false;
              resolve(false);
            } else {
              context.commit("UpdateUser", response.data);
              context.commit("CreateAuthToken", payload);

              const account = sessionStorage.getItem("AccountExternalId");
              if (
                account != undefined &&
                account != "undefined" &&
                account != null
              ) {
                this.dispatch("GetAccountInformation", account)
                .catch(() => {});
              }
              this.state.UserLoading = false;
              resolve(true);
            }
          })
          .catch((e) => {
            this.state.UserLoading = false;
            reject(e);
          });
      });
    },
    RefreshUser(context, payload) {
      return new Promise((resolve, reject) => {
        this.state.UserLoading = true;
        axios
          .get(context.state.baseUrl + "/api/User", {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit("UpdateUser", response.data);

            if (response.data.linkedAccounts.length == 1) {
              context.commit(
                "SetActiveAccount",
                response.data.linkedAccounts[0]
              );

              this.state.UserLoading = false;
              resolve(false);
            } else {
              const account = sessionStorage.getItem("AccountExternalId");
              if (
                account != undefined &&
                account != "undefined" &&
                account != null &&
                account.trim() != ""
              ) {
                this.dispatch("GetAccountInformation", account).catch(() => {
                 // alert("here I am");
                });
              }
              this.state.UserLoading = false;
              resolve(true);
            }
          })
          .catch((e) => {
            this.state.UserLoading = false;
            reject(e);
          });
      });
    },
    ChangePassword(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            context.state.baseUrl + "/api/profile/User/changepassword",
            {},
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjects(context, payload) {
      this.state.ProjectsLoading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(context.state.baseUrl + "/api/" + payload + "/project", {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            // JSON responses are automatically parsed.
            context.commit("UpdateProjects", response.data);
            resolve(null);
          })
          .catch((e) => {
            context.state.ProjectsLoading = false;
            reject(e);
          });
      });
    },
    GetOrders(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(context.state.baseUrl + "/api/" + payload + "/orders", {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetPreviousOrders(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl + "/api/" + payload + "/orders/completed",
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetOrderById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/" +
              payload.AccountId +
              "/orders/" +
              payload.OrderId,
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetOrderApprovalKeysById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/" +
              payload.AccountId +
              "/orders/" +
              payload.OrderId +
              "/reviewablekeys/" +
              payload.ToLanguage,
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetOrderPendingKeysById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/" +
              payload.AccountId +
              "/orders/" +
              payload.OrderId +
              "/outstandingkeys/" +
              payload.ToLanguage +"?page="+payload.page ,
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RemoveLock(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/orders/project/${payload.ProjectId}/order/${payload.OrderId}/unlock`,
            {},
            {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    LoadFileFormatSettings(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/" +
              payload.AccountId +
              "/download/" +
              payload.projectId +
              "/formats/" +
              payload.fileFormat,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetDownloadLink(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/" +
              payload.AccountId +
              "/download/" +
              payload.ProjectId +
              "/" +
              payload.RequestId,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetBillingHistory(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/account/" +
              payload +
              "/billinghistory",
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTransaction(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/account/" +
              payload.AccountId +
              "/billinghistory/" +
              payload.Id,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetInvoiceDownloadLink(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            context.state.baseUrl +
              "/api/account/" +
              payload.AccountId +
              "/billinghistory/" +
              payload.Id +"/invoice",
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            // JSON responses are automatically parsed.
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },




    GetLanguages(context) {
      this.state.LanguagesLoading = true;
      return new Promise((resolve, reject) => {
        axios
          .get(context.state.baseUrl + "/api/Languages")
          .then((response) => {
            // JSON responses are automatically parsed.
            context.commit("UpdateLanguages", response.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetSupportedLanguages(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(context.state.baseUrl + "/api/Languages/supported")
          .then((response) => {
            // JSON responses are automatically parsed.
            context.commit("UpdateSupportedLanguages", response.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTranslationForKey(context, payload) {
      return new Promise((resolve, reject) => {
        ///api/{externalAccountId}/project/{projectId}/keys/{projectKeyId}/language/{fromLanguageId}/{toLanguageId}/translations
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.accountId}/project/${payload.projectId}/keys/${payload.projectKeyId}/language/${payload.fromLanguageId}/${payload.toLanguageId}/translations/${payload.itemType}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetMemoryTranslationForKey(context, payload) {
      return new Promise((resolve, reject) => {
        ///api/{externalAccountId}/project/{projectId}/keys/{projectKeyId}/language/{fromLanguageId}/{toLanguageId}/translations
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.accountId}/project/${payload.projectId}/keys/${payload.projectKeyId}/language/${payload.fromLanguageId}/${payload.toLanguageId}/memorytranslations/${payload.itemType}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RequestRevision(context, payload) {
      return new Promise((resolve, reject) => {
        //{externalAccountId}/project/{projectId}/keys/{projectKeyId}/language/{projectLanguageId}/requestrevision
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.projectKeyId}/language/${payload.projectLanguageId}/RequestRevision`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    AcceptTranslation(context, payload) {
      return new Promise((resolve, reject) => {
        //{externalAccountId}/project/{projectId}/keys/{projectKeyId}/language/{projectLanguageId}/requestrevision
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.projectKeyId}/language/${payload.projectLanguageId}/approvetranslation`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    SetNewUserEmail(context, payload) {
      context.state.NewUserEmail = payload;
    },
    GetNewUserEmail(context) {
      const i = context.state.NewUserEmail;
      context.state.NewUserEmail == "";
      return i;
    },
    PostProject(context, payload) {
      context.state.ProjectsLoading = true;
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            context.commit("AddProjects", response.data);
            context.state.ProjectsLoading = false;
            resolve(response.data.externalId);
          })
          .catch((e) => {
            context.state.ProjectsLoading = false;
            reject(e);
          });
      });
    },
    PostAccount(context, payload) {
      context.state.ProjectsLoading = true;
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.baseUrl}/api/account`, payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GettingStarted(context, payload) {
      context.state.ProjectsLoading = true;
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/getstarted`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutProject(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            context.commit("UpdateSingleProject", response.data);
            context.state.ProjectsLoading = false;
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutUserAccept(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/${payload.externalId}/accept`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RefreshProject(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            context.commit("UpdateSingleProject", response.data);
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteProject(context, payload) {
      // return context.dispatch("DeleteCommon", {
      //   url: `/api/${payload.AccountId}/project/${payload.ProjectId}`,
      //   dispatch: "GetProjects",
      //   loading: (result: boolean): void => {
      //     context.state.ProjectsLoading = result;
      //   },
      // });

      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            context.dispatch("GetProjects", payload.AccountId);

            //context.commit("UpdateSingleProject", response.data);
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteTeamMember(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/${payload.UserId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => resolve(response))
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutLanguage(context, payload) {
      return context.dispatch("PutCommon", {
        url: `/api/${payload.AccountId}/project/${payload.projectExternalId}/languages/${payload.externalId}`,
        dispatch: "UpdateSingleLanguage",
        payload,
        loading: (result: boolean): void => {
          context.state.ProjectsLoading = result;
        },
      });
    },
    DeleteLanguage(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectExternalId}/languages/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            for (const i in context.state.Projects) {
              if (
                context.state.Projects[i].externalId ==
                payload.projectExternalId
              ) {
                context.state.Projects[i].languages = context.state.Projects[
                  i
                ].languages.filter((value: any) => {
                  // debugger;
                  return value.externalId !== payload.externalId;
                });
                break; //Stop this loop, we found it!
              }
            }
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostLanguage(context, payload) {
      return context.dispatch("PostCommon", {
        url: `/api/${payload.AccountId}/project/${payload.externalId}/languages`,
        dispatch: "UpdateSingleProject",
        payload: payload.payload,
        loading: (result: boolean): void => {
          context.state.ProjectsLoading = result;
        },
      });
    },
    DoSearch(context, payload) {
      if (payload.ShowProjectResults && payload.ProjectId != "") {
        return new Promise((resolve, reject) => {
          axios
            .get(
              `${context.state.baseUrl}/api/${payload.AccountId}/search/${payload.ProjectId}/?searchTerm=${payload.searchTerm}`,
              {
                headers: { Authorization: `Bearer ${context.state.authToken}` },
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/search?searchTerm=${payload.searchTerm}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostKey(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/keys`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            for (const i in context.state.Projects) {
              if (
                context.state.Projects[i].externalId ==
                response.data.projectExternalId
              ) {
                context.state.Projects[i].keysCount++;
                break; //Stop this loop, we found it!
              }
            }
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostTerm(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/glossary`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostNewAutomation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/automation`,
            payload.Form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutAutomation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/automation/${payload.AutomationId}`,
            payload.Form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RetentionOffer(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/subscription/retention`,
            payload.Form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutTerm(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectExternalId}/glossary/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutKey(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            context.state.baseUrl +
              `/api/${payload.AccountId}/project/${payload.PprojectExternalId}/keys/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutBulkKey(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            context.state.baseUrl +
              `/api/${payload.AccountId}/project/${payload.ProjectExternalId}/keys/bulk`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutKeyLanguage(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            context.state.baseUrl +
              `/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.ProjectkeysId}/language/${payload.projectLanguageId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            axios
              .get(
                `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.ProjectkeysId}`,
                {
                  headers: {
                    Authorization: `Bearer ${context.state.authToken}`,
                  },
                }
              )
              .then((getresponse) => {
                resolve(getresponse);
              });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteUploadfile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/upload/${payload.FileId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then(() => {
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    ProcessFileUpload(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/upload`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then(() => {
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetSignedUploadUrl(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/upload?filename=${payload.filename.name}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data.url);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetSignedScreenShotUrl(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/screenshot/upload?filename=${payload.filename.name}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data.url);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetUploadStatus(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/getupload`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsKey(context, payload) {
      let fliterString = "";
      if (payload.sortOrder != undefined && payload.sortOrder.trim() != "") {
        fliterString = `&sortOrder=${payload.sortOrder}`;
      }
      if (payload.searchTerm != undefined && payload.searchTerm.trim() != "") {
        fliterString += `&contains=${payload.searchTerm}`;
      }
      if (payload.tag != undefined) {
        fliterString += `&orTags=${payload.tag}`;
      }
      if (payload.platform != undefined) {
        fliterString += `&orPlatform=${payload.platform}`;
      }
      if (payload.glossaryId != undefined) {
        fliterString += `&GlossaryId=${payload.glossaryId}`;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys?page=${payload.page}&contains=${fliterString}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsKeyHistory(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.projectKeyId}/history/${payload.languageKeyId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsTag(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/tag`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsTagsExtend(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/tag/extended`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsPlatform(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/platform`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsPlatformsExtend(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/platform/extended`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectKeyById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ExternalProjectId}/keys/${payload.keyId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsRepos(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/Integration`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsScreenshots(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/screenshots`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsGlossary(context, payload) {
      let fliterString = "";
      if (payload.sortOrder != undefined && payload.sortOrder.trim() != "") {
        fliterString = `&sortOrder=${payload.sortOrder}`;
      }
      if (payload.searchTerm != undefined && payload.searchTerm.trim() != "") {
        fliterString += `&contains=${payload.searchTerm}`;
      }
      if (payload.tag != undefined) {
        fliterString += `&orTags=${payload.tag}`;
      }
      if (payload.GlossaryId != undefined) {
        fliterString += `&glossaryId=${payload.GlossaryId}`;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/glossary?page=${payload.page}${fliterString}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectAutomation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/automation`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAutomationById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/automation/${payload.AutomationId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteAutomation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/automation/${payload.AutomationId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTimeZones(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/timezones`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsScreenshotById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/screenshots/${payload.screenshotId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectTasks(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/task`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTaskById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/task/${payload.TaskId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTaskLanguageById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/task/${payload.TaskLanguageId}/keys?page=${payload.Page}&pageLength=50`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetKeyStatus(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/task/${payload.TaskLanguageId}/keys/${payload.keyId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    GetProjectsUsers(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/users`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetUsersEmail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/email?email=${payload.payload}&projectId=${payload.projectId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    CreateNewUser(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/users`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    CreateNewTeam(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/teams`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    UpdateUser(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    UpdateTeam(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/teams/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    ResetUserPassword(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/${payload.externalId}/resetpassword`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetUsers(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/users/${payload.ProjectId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTeamById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/teams/${payload.ProjectId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteTeam(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/teams/${payload.TeamId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetCountries(context) {
      if (!context.state.CountriesLoaded && !context.state.CountriesLoading) {
        context.state.CountriesLoading = true;
        return new Promise((resolve, reject) => {
          axios
            .get(`${context.state.baseUrl}/api/country`, {
              headers: { Authorization: `Bearer ${context.state.authToken}` },
            })
            .then((response) => {
              context.commit("UpdateCountries", response.data);
              resolve(response.data);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
    },
    GetTasks(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload}/task`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetTasksByUser(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/task/user/${payload.UserId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostTask(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ExternalProjectId}/task`,
            payload.form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data.externalId);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetKeyCount(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ExternalProjectId}/task/count`,
            payload.form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAccountUser(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload}/users`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAccountUserTeam(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ProjectId}/users-team`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAccountTeam(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload}/teams`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetDownloadFormats(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload}/download/formats`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit("UpdateDownloadFormats", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAccountInformation(context, payload) {
      return new Promise((resolve, reject) => {
        context.state.AccountIsLoading = true;
        context.state.AccountLoaded = false;
        if (payload == null || payload == undefined || payload.trim() == "") {
          reject();
        }

        axios
          .get(`${context.state.baseUrl}/api/account/${payload}`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit("UpdateAccountInformation", response.data);
            context.commit("UnknownAccount", false);
            context.state.AccountIsLoading = false;
            resolve(response.data);
          })
          .catch((e) => {
            context.commit("UnknownAccount", true);
            reject(e);
            context.state.AccountIsLoading = false;
          });
      });
    },
    GetPaymentMethods(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/account/${payload}/billingmethods`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutPaymentMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/billingmethods/new`,
            payload.newPaymentPayload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    MakePrimaryPaymentMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/billingmethods/${payload.billingMethodsId}/makeprimary`,
            {},
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeletePaymentMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/billingmethods/${payload.billingMethodsId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetAvailableTariffs(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/tariffs`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutConfirmSubscription(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/subscription`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutSubscriptionPaymentMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}/subscription/billingmethod`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutAccount(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/account/${payload.AccountId}`,
            payload.AccountOwnership,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    CloseAccount(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${context.state.baseUrl}/api/account/${payload.AccountId}`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
            data: payload.form,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    UpdateScreenshotUrl(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/screenshots/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetScreenshotByPhraseId(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/screenshots/phrases/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsRepoById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetProjectsRepoHistoryById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}/history`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetChatMessages(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.baseUrl}/api/${payload}/messages`, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetChatSinceMessages(context, payload) {
      const timestampInSeconds = Math.floor(payload.sinceTime / 1000);

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.baseUrl}/api/${payload.AccountId}/messages/project/${payload.projectId}/since/${timestampInSeconds}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostChatMessages(context, payload) {
      return new Promise((resolve, reject) => {
        //https://localhost:5001/api/3d411e29-ded3-4d30-a750-3c02befd33a4/orders/project/843a6e39-c652-4fdb-a3f3-b991fa5ca333/quote?fromLanguage=en&toLanguages=fr&toLanguages=hi&providerId=1&qualityLevel=1'
        const address = `${context.state.baseUrl}/api/${payload.AccountId}/messages`;
        axios
          .post(address, payload.Payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RefreshBranchesRepoById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}/branch/refresh`,
            {},
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    ReAuthenticate(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}/ReAuthenticate`,
            {},
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    SetBranchesRepoById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}/branch`,
            payload.form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    SetFileRepoById(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}/files`,
            payload.form,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    UpdateIntergationNotification(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.repoId}`,
            payload.data,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GetQuote(context, payload) {
      return new Promise((resolve, reject) => {
        //https://localhost:5001/api/3d411e29-ded3-4d30-a750-3c02befd33a4/orders/project/843a6e39-c652-4fdb-a3f3-b991fa5ca333/quote?fromLanguage=en&toLanguages=fr&toLanguages=hi&providerId=1&qualityLevel=1'
        const address = `${context.state.baseUrl}/api/${payload.AccountId}/orders/project/${payload.externalId}/quote`

        axios
          .post(address, payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostOrder(context, payload) {
      return new Promise((resolve, reject) => {
        //https://localhost:5001/api/3d411e29-ded3-4d30-a750-3c02befd33a4/orders/project/843a6e39-c652-4fdb-a3f3-b991fa5ca333/quote?fromLanguage=en&toLanguages=fr&toLanguages=hi&providerId=1&qualityLevel=1'
        const address = `${context.state.baseUrl}/api/${payload.AccountId}/orders/project/${payload.externalId}/quote/${payload.QuoteexternalId}`;

        axios
          .post(address, payload.Payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    LoadMoreProjectsKeys(context, payload) {
      if (payload.projectId != undefined) {
        let fliterString = "";
        if (payload.sortOrder != undefined && payload.sortOrder.trim() != "") {
          fliterString = `&sortOrder=${payload.sortOrder}`;
        }
        if (
          payload.searchTerm != undefined &&
          payload.searchTerm.trim() != ""
        ) {
          fliterString += `&contains=${payload.searchTerm}`;
        }
        if (payload.tag != undefined) {
          fliterString += `&orTags=${payload.tag}`;
        }
        if (payload.platform != undefined) {
          fliterString += `&orPlatform=${payload.platform}`;
        }
        if (payload.glossaryId != undefined) {
          fliterString += `&GlossaryId=${payload.glossaryId}`;
        }

        return new Promise((resolve, reject) => {
          axios
            .get(
              `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys?page=${payload.page}${fliterString}`,
              {
                headers: { Authorization: `Bearer ${context.state.authToken}` },
              }
            )
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
    },
    DeleteKey(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/keys/${payload.keyId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteTerm(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/glossary/${payload.editId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteTag(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/tag/${payload.editId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeletePlatform(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/platform/${payload.editId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteTask(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/task/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204 || response.status === 200);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    CompleteTask(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/task/${payload.externalId}/markcomplete`,
            {},
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204 || response.status === 200);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteIntergration(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/Integration/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    DeleteScreenshot(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.projectId}/screenshots/${payload.externalId}`,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.status === 204);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostNewRepository(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/Integration`,
            payload.payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostTag(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/tag`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostPlatform(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.externalId}/platform`,
            {
              title: payload.Platform,
            },
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutTag(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ExternalProjectId}/tag/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutPlatform(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/project/${payload.ExternalProjectId}/Platform/${payload.externalId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    RequestDownload(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.baseUrl}/api/${payload.AccountId}/download/${payload.ProjectId}`,
            payload,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PutOAuth(context, payload) {
      const message = {
        OAuthToken: payload.Code,
        Error: payload.Error,
        ErrorMesage: payload.ErrorMessage,
      };
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${context.state.baseUrl}/api/${payload.AccountId}/Integration/${payload.State}/oauth`,
            message,
            { headers: { Authorization: `Bearer ${context.state.authToken}` } }
          )
          .then((response) => {
            resolve(response.data.projectExternalId);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    PostCommon(context, payload) {
      return new Promise((resolve, reject) => {
        payload.loading(true);
        axios
          .post(context.state.baseUrl + payload.url, payload.payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit(payload.dispatch, response.data);
            payload.loading(false);
            resolve(response.data.externalId ?? response.data.Id);
          })
          .catch((e) => {
            payload.loading(false);
            reject(e);
          });
      });
    },
    GetCommon(context, payload) {
      return new Promise((resolve, reject) => {
        payload.loading(true);

        axios
          .get(context.state.baseUrl + payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit(payload.dispatch, response.data);
            payload.loading(false);
            resolve(null);
          })
          .catch((e) => {
            payload.loading(false);
            reject(e);
          });
      });
    },
    PutCommon(context, payload) {
      return new Promise((resolve, reject) => {
        payload.loading(true);
        axios
          .put(context.state.baseUrl + payload.url, payload.payload, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then((response) => {
            context.commit(payload.dispatch, response.data);
            payload.loading(false);
            resolve(response.data.externalId ?? response.data.Id);
          })
          .catch((e) => {
            payload.loading(false);
            reject(e);
          });
      });
    },
    DeleteCommon(context, payload) {
      return new Promise((resolve, reject) => {
        payload.loading(true);
        axios
          .delete(context.state.baseUrl + payload.url, {
            headers: { Authorization: `Bearer ${context.state.authToken}` },
          })
          .then(() => {
            context.dispatch(payload.dispatch);
            payload.loading(false);
            resolve(null);
          })
          .catch((e) => {
            payload.loading(false);
            reject(e);
          });
      });
    },
  },
  modules: {},
});
