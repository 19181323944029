
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  data() {
    return {
      openDialogWatch: false,
    };
  },
  methods: {
    CloseErrorOccured() {
      this.$emit("CloseErrorOccured");
    },
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  props: {
    openDialog: Boolean,
    Title: String,
    ItemName: String
  },
});
