
import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import UnhandleErrorDialog from "@/components/UnhandleErrorDialog.vue";
import NoPermission from "@/components/NoPermission.vue";

export default mixins(Common).extend({
  data() {
    return {
      IsRequesting: false,
      openDialogWatch: false,
      ShowComplete: false,
    };
  },
  methods: {
    ClosePasswordResetModal() {
      this.ShowComplete = false;
      this.$emit("ClosePasswordResetModal");
    },
    ResetPasswordAction() {
      this.IsRequesting = true;

      this.$store
        .dispatch("ChangePassword",   {})
        .then(() => {
          
          this.IsRequesting = false;
          this.ShowComplete = true;
        })
        .catch((error) => {
          this.IsRequesting = false;
          this.CheckError(error);
          
        });
    },
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  components: {
    UnhandleErrorDialog,
    NoPermission
  },
  props: {
    openDialog: Boolean,
    TeamMemberId: String,
    ProjectId: String,
  },
});
