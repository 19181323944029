
import mixins from "vue-typed-mixins";
import Projects from "@/Mixins/Projects";
import Common from "@/Mixins/Common";

export default mixins(Projects, Common).extend({
  data() {
    return {
      LanguageId: [],
      Open: false,
      OpenGeneralError: false,
      text: "",
      SelectProjects: "",
      Loading: true,
      blukMessage: [] as any,
      message: [] as any,
      TextMessage: "",
      CurrentUrl: "",
      UrlKey: "",
      keyexternalId: "",
      HasLinkTo: "",
      rules: {
        required: (v: any) => !!(v && v.length) || this.$i18n.t("REQUIRED"),
      },
    };
  },
  methods: {
    CloseModal() {
      this.Open = false;
    },
    OpenModal() {
      this.Open = true;
      if (this.SelectProjects == "") {
        if (
          this.RouteName == "viewproject" ||
          this.RouteName == "bilingualviewproject" ||
          this.RouteName == "viewsinglekey" ||
          this.RouteName == "viewsinglekeynokey" ||
          this.RouteName == "viewIntergration" ||
          this.RouteName == "viewscreenshots" ||
          this.RouteName == "viewProjectTasks" ||
          this.RouteName == "viewTasksById" ||
          this.RouteName == "CreateProjectTasks" ||
          this.RouteName == "viewprojectusers" ||
          this.RouteName == "viewIntergrationbyid"
        ) {
          this.SelectProjects = this.Id;
        }
      }
    },
    GetInitMessages() {
      var checkTime = new Date().getTime();
      //alert("Test");
      this.$store
        .dispatch("GetChatMessages", this.AccountId)
        .then((response: any) => {
          //alert(response);
          this.blukMessage = response;
          this.blukMessage.forEach((element: any) => {
            element.checkTime = checkTime;
          });
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        })
        .catch((error) => {
          this.CheckError(error);
        });
    },
    GetSinceMessages(project: string) {
      if (this.blukMessage == null) {
        return;
      }
      let i = this.blukMessage.find(
        (e: any) => e.projectExternalId === project
      );
      if (i != null) {
        let re = {
          projectId: project,
          sinceTime: i.checkTime,
          AccountId: this.AccountId,
        };
        this.$store
          .dispatch("GetChatSinceMessages", re)
          .then((response: any) => {
            if (response.length != 0) {
              response.forEach((element: any) => {
                i.messages = i.messages.filter((e: any) => {
                  return e.externalId != element.externalId;
                });
                i.messages.push(element);
              });

              this.ShowSelectProjects();
              this.$nextTick(function () {
                var element = document.getElementById("ChatPanel");

                if (element != null) {
                  element.scrollTop = element.scrollHeight;
                }
              });
            }
          })
          .catch((error) => {
            this.CheckError(error);
          });
      }
    },
    ShowSelectProjects() {
      if (this.SelectProjects != "") {
        let i = this.blukMessage.find(
          (e: any) => e.projectExternalId === this.SelectProjects
        );
        if (i != null) {
          this.message = i.messages;
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        } else {
          this.message = [];
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        }
      }
    },
    SendMessage() {
      this.TextMessage = this.TextMessage.trim();

      if (this.TextMessage.length != 0) {
        this.$store
          .dispatch("PostChatMessages", {
            projectId: this.Id,
            Payload: {
              message: this.TextMessage,
              ProjectId: this.SelectProjects,
              ProjectKeyexternalId: this.keyexternalId,
              Url: this.CurrentUrl,
            },
            AccountId: this.AccountId,
          })
          .then((response: any) => {
            this.TextMessage = "";
            this.message.push(response);
            this.RemoveLink();
            this.$nextTick(function () {
              var element = document.getElementById("ChatPanel");

              if (element != null) {
                element.scrollTop = element.scrollHeight;
              }
            });
          })
          .catch((error) => {
            this.CheckError(error);
          });
      }
    },
    RemoveLink() {
      this.CurrentUrl = "";
      this.UrlKey = "";
      this.keyexternalId = "";
    },
    OpenLink(m: any) {
      //alert(m)
      window.location.href = m;
      //window.history.pushState(m, );
    },
  },
  created() {},
  watch: {
    HasAccount() {
      if (this.HasAccount && this.isAuthenticated) {
        this.GetInitMessages();
      }
    },
    isAuthenticated() {
      if (this.HasAccount && this.isAuthenticated) {
        this.GetInitMessages();
      }
    },
    SelectProjects() {
      this.ShowSelectProjects();
    },
  },
  computed: {
    HasAccount() {
      if (this.AccountId == null || this.AccountId == undefined) {
        return false;
      }
      return true;
    },
    RouteName(): string {
      if (this.$route.name == null || this.$route.name == undefined) {
        return "";
      }
      return this.$route.name.toString();
    },
    Id(): string {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.Loading = true;
    this.$root.$on("OpenChat", (text: any) => {
      this.SelectProjects = text.projectId;
      this.CurrentUrl = text.url;
      this.UrlKey = text.key;
      this.keyexternalId = text.keyexternalId;
      this.HasLinkTo = this.$i18n
        .t("HasLinkTo", {
          key: text.key,
        })
        .toString();
      this.Open = true;
      this.$nextTick(function () {
        var element = document.getElementById("ChatPanel");

        if (element != null) {
          element.scrollTop = element.scrollHeight;
        }
      });
      this.text = text;
      //console.log(text);
    });
    this.$root.$on("ChatMessage", (text: any) => {
      this.GetSinceMessages(text.ProjectId);
    });
    //alert("Test");
    if (this.HasAccount) {
      this.GetInitMessages();
    }
  },
});
